import { RouteRecordRaw } from 'vue-router';

import { Auth } from '$common/modules/auth/middelwares.ts';

export const routes: RouteRecordRaw[] = [
  {
    path: 'feature-flags',
    meta: { middlewares: [Auth] },
    component: () => import('./layouts/FeatureFlagLayout.vue'),
    children: [
      {
        path: '',
        name: 'FeatureFlagGeneralView',
        component: () => import('./views/FeatureFlagGeneralView.vue'),
      },
    ],
  },
];

import { RouteRecordRaw } from 'vue-router';

import { Auth } from '$common/modules/auth/middelwares.ts';

export const routes: RouteRecordRaw[] = [
  {
    path: 'tenants',
    meta: { middlewares: [Auth] },
    children: [
      {
        path: '',
        name: 'TenantList',
        component: () => import('./views/TenantList.vue'),
      },
      {
        path: ':tenant',
        component: () => import('./layouts/TenantLayout.vue'),
        children: [
          {
            path: '',
            name: 'TenantGeneralView',
            component: () => import('./views/TenantGeneralView.vue'),
          },
          {
            path: 'users',
            name: 'TenantUsersView',
            component: () => import('./views/TenantUsersView.vue'),
          },
          {
            path: 'settings',
            name: 'TenantSettingsView',
            component: () => import('./views/TenantSettingsView.vue'),
          },
          {
            path: 'finance',
            name: 'TenantFinanceView',
            component: () => import('./views/TenantFinanceView.vue'),
          },
          {
            path: 'tokens',
            name: 'TenantApiTokenView',
            component: () => import('./views/TenantApiTokenView.vue'),
          },
        ],
      },
    ],
  },
];
